import { Checklist } from "types/Checklist";
import { ServerTaskType } from "~/types/enums/ServerTaskType";

import {
  Timestamp,
  addDoc,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";

export const useApplyChecklistToDocuments = definePiniaStore(
  "/apply/checklists/documents",
  () => {
    const applyChecklistToDocument = async (
      checklistId: string,
      checklistPath: string,
      documentId: string
    ) => {
      const db = useFirestore();

      const docRef = doc(db, `/documents/${documentId}`);
      await updateDoc(docRef, {
        checklistId: checklistId,
        checklistPath: checklistPath,
        state: DocumentState.evaluating,
      });

      const serverTasksRef = collection(
        db,
        `taskQueues/reloadDocumentChecklist/queuedTasks`
      );

      await addDoc(serverTasksRef, {
        createdOnTimestamp: Timestamp.now(),
        documentId: documentId,
        checklistId: checklistId,
        checklistPath: checklistPath,
      });
    };

    const applyChecklistToDocumentNow = async (
      checklistId: string,
      checklistPath: string,
      documentId: string
    ) => {
      const db = useFirestore();

      const docRef = doc(db, `/documents/${documentId}`);
      await updateDoc(docRef, {
        checklistId: checklistId,
        checklistPath: checklistPath,
        state: DocumentState.evaluating,
      });
      await $fetch(`/api/document/${documentId}/checklist/reload`, {
        method: "GET",
        headers: await useApiHeaders(),
      });
    };

    return {
      applyChecklistToDocument,
      applyChecklistToDocumentNow,
    };
  }
);
